/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

type ShowImagesProps = {
  images: any;
  altText: string;
  gridSize: number;
};

export default function ShowImages(props: ShowImagesProps) {
  const { images, altText, gridSize } = props;
  const matches = useMediaQuery("(max-width: 850px)");

  return (
    <Grid item xs={12} md={gridSize} lg={gridSize}>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        style={{ zIndex: 1 }}
      >
        {images && images.length > 0 ? (
          <>
            {images.map((image: any, key: number) => (
              <SwiperSlide style={{ height: matches ? 420 : 450 }} key={key}>
                <img
                  src={
                    image.path
                      ? process.env.REACT_APP_URL_STATUS === "STAGING"
                        ? `${process.env.REACT_APP_URL}${image.path}`
                        : image.path
                      : process.env.REACT_APP_URL_STATUS === "STAGING"
                      ? `${process.env.REACT_APP_URL}${image}`
                      : image
                  }
                  alt={altText}
                  style={{
                    objectFit: "cover",
                    height: "80%",
                  }}
                />
              </SwiperSlide>
            ))}
          </>
        ) : (
          <SwiperSlide>
            <img
              src={
                process.env.REACT_APP_URL_STATUS === "STAGING"
                  ? `${process.env.REACT_APP_URL}manufactures/NoImage.png`
                  : "/manufactures/NoImage.png"
              }
              alt={altText}
              style={{ objectFit: "cover" }}
            />
          </SwiperSlide>
        )}
      </Swiper>
    </Grid>
  );
}
