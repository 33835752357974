/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import ShowImages from "./ShowImages";

type QuestionsTemplateProps = {
  device: any;
  children: any;
};

export default function QuestionsTemplate(props: QuestionsTemplateProps) {
  const { device, children } = props;
  const matches = useMediaQuery("(max-width: 850px)");
  const [top, setTop] = useState(0);

  useEffect(() => {
    const paper = document.getElementById("grid_questions") as any;
    const rect = paper.getBoundingClientRect();
    if (rect.height > 500) {
      setTop(350);
    } else {
      setTop(0);
    }
  }, [children]);

  return (
    <Grid container spacing={2} style={{ height: "100%", marginTop: 5 }}>
      <ShowImages
        images={device.images}
        altText={device.deviceName}
        gridSize={6}
      />
      <Grid
        id="grid_questions"
        item
        xs={12}
        md={6}
        lg={6}
        style={
          matches
            ? {
                position: "absolute",
                top: top !== 0 ? top : 500,
                right: 0,
                left: 0,
                zIndex: 2,
                background:
                  "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,0.7903536414565826) 85%, rgba(255,255,255,0) 100%)",
                padding: "120px 10px 80px 10px",
                textAlign: "center",
              }
            : { textAlign: "left" }
        }
      >
        {children}
      </Grid>
    </Grid>
  );
}
