/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Vocabulary } from "../../Utils/vocabulary";
import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { StepContext } from "../../Context/stepContext";
import { LocalUrlEnum } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";

const stateMachine = ["manufacturers", "devices", "vand"];

type DashboardProps = {
  classes: any;
  children: any;
};
function Dashboard(props: DashboardProps) {
  const { classes, children } = props;
  const navigate = useNavigate();
  const stepContext = useContext(StepContext);
  const matches = useMediaQuery("(max-width: 750px)");
  const [openModal, setOpenModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 1010,
          backgroundColor: "white",
          boxShadow: "1px 1px 12px 5px #e9e9e9",
          width: "100%",
          paddingRight: "10px",
        }}
      >
        <Grid container spacing={0}>
          {stepContext.step === 0 ? (
            ""
          ) : (
            <Grid
              item
              xs={6}
              md={6}
              lg={6}
              style={{ padding: "0px !important" }}
            >
              <IconButton
                disabled={stepContext.step === 0 ? true : false}
                className={classes.button}
                onClick={() => {
                  if (stepContext.step.toString() === "hardStop") {
                    stepContext.setStep(0);
                    navigate(LocalUrlEnum.hardStop);
                  }
                  if (stepContext.step - 1 >= 0) {
                    if (stepContext.step - 1 === 0) {
                      navigate("/");
                      stepContext.setStep(stepContext.step - 1);
                    }
                    if (stepContext.step - 1 === 1) setOpenModal(true);
                  }
                }}
              >
                <KeyboardBackspaceIcon
                  fontSize="large"
                  style={{ marginLeft: 5 }}
                />
                <p style={{ fontSize: 17, marginLeft: "5px" }}>
                  Către {Vocabulary[stateMachine[stepContext.step - 1]]}
                </p>
              </IconButton>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            textAlign={stepContext.step === 0 ? "left" : "right"}
            style={{ padding: "0px !important" }}
          >
            <a
              href="https://www.yzzy.ro/"
              className={matches ? classes.logo : ""}
            >
              <div
                style={{
                  padding: "15px 10px 0px 10px",
                  marginTop: 0,
                }}
              >
                <img src={"/yzzy-logo.png"} alt="YZZY" width="150px" />
              </div>
            </a>
          </Grid>
        </Grid>
      </div>
      {stepContext.step.toString() === "hardStop" ? (
        ""
      ) : (
        <div style={{ margin: "20px 20px 20px 20px", paddingTop: 80 }}>
          <Stepper alternativeLabel activeStep={stepContext.step}>
            {stateMachine.map((label: string) => (
              <Step
                key={label}
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#358800",
                  },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "#358800",
                  },
                }}
              >
                <StepLabel style={{ color: "#358800" }}>
                  {Vocabulary[label].toUpperCase()}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      )}
      {children}
      {showMessage ? (
        <div
          style={{
            padding: "5px",
            position: "fixed",
            bottom: 0,
            right: 0,
            zIndex: 10,
            width: "100%",
            backgroundColor: "#e8fbdc",
            boxShadow: "1px 1px 12px 5px #e9e9e9",
          }}
        >
          <CloseIcon
            fontSize="medium"
            onClick={() => setShowMessage(false)}
            style={{
              position: "absolute",
              right: 10,
              color: "#647273",
              cursor: "pointer",
            }}
          />
          <div
            style={
              matches
                ? {}
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
            }
          >
            <div>
              <p style={{ marginTop: 25, fontSize: 15, color: "#647273" }}>
                &nbsp; &nbsp;În acest moment site-ul este în versiunea de
                testare. Unele procese încă necesită îmbunătățire.
              </p>
              <p style={{ fontSize: 15, color: "#647273" }}>
                &nbsp; &nbsp;Vă rugăm să aveți în vedere că unele
                funcționalități pot întâmpina inconveniente. Apreciem sprijinul
                dvs.
              </p>
            </div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#358800",
                minWidth: 120,
                marginLeft: matches ? 10 : 40,
                width: matches ? "70%" : "auto",
                marginBottom: 10,
              }}
              onClick={() => setShowMessage(false)}
            >
              Închide
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: 800,
            },
          },
        }}
        id="previewDialogContent"
      >
        <DialogTitle
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            margin: 10,
          }}
        >
          <p style={{ position: "absolute", right: 20 }}>
            <CloseIcon
              onClick={() => setOpenModal(false)}
              style={{ cursor: "pointer", fontSize: 28 }}
            />
          </p>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: 20, margin: "0px 0px 30px 0px" }}>
              Sunteți sigur că doriți să renunțați la completarea formularului?
            </p>
            <Button
              variant="contained"
              style={{
                background: "linear-gradient(90deg,#cc80ff 0%,#90f 65%)",
                minWidth: 120,
                margin: 20,
                width: matches ? "70%" : "auto",
              }}
              onClick={() => setOpenModal(false)}
            >
              Nu
            </Button>
            <Button
              variant="contained"
              style={{
                background:
                  "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)",
                minWidth: 120,
                margin: 20,
                width: matches ? "70%" : "auto",
              }}
              onClick={() => {
                setOpenModal(false);
                navigate(LocalUrlEnum.devices);
                stepContext.setStep(stepContext.step - 1);
              }}
            >
              Da
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const styles = (theme: any) =>
  createStyles({
    button: {
      color: "#358800 !important",
      display: "flex !important",
      borderRadius: "0px !important",
      border: "none !important",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
      "&:active": {
        border: "none !important",
      },
      "&:focus": {
        outline: "none !important",
      },
    },
  });

export default withStyles(styles, { withTheme: true })(Dashboard);
