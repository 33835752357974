/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Grid, Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
// import { Vocabulary } from "../../Utils/vocabulary";
type QuestionnaireDoneProps = {
  consignmentPrice: number;
  estimatedPrice: number;
  handleChangeTypePaymentAndPrice: (price: number, paymentType: string) => void;
  handleChangeLinearProgressForNext: () => void;
};
export default function EstimatedPrice(props: QuestionnaireDoneProps) {
  const navigate = useNavigate();
  const {
    consignmentPrice,
    estimatedPrice,
    handleChangeTypePaymentAndPrice,
    handleChangeLinearProgressForNext,
  } = props;

  return (
    <div
      style={{
        color: "#647273",
        margin: 30,
      }}
    >
      <Grid container spacing={10} justifyContent="center">
        {estimatedPrice <= 50 ? (
          ""
        ) : (
          <Grid item xs={12} md={6} lg={6}>
            <Paper>
              <div
                style={{
                  padding: 10,
                  color: "white",
                  background: "linear-gradient(90deg,#cc80ff 0%,#90f 65%)",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}
              >
                <h2> Plată în 30 de zile</h2>
              </div>
              <div style={{ padding: "0px 20px 20px 20px" }}>
                <h1 style={{ fontSize: 40 }}>
                  {consignmentPrice} {"  "}
                  <span style={{ fontSize: 20 }}>Lei</span>
                </h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    minHeight: 90,
                  }}
                >
                  <CheckIcon
                    style={{
                      color: "rgba(81,158,0,1)",
                      padding: "0px 10px 20px 0px",
                    }}
                  />
                  <p style={{ textAlign: "left" }}>
                    Plată în 30 de zile ( imediat după ce telefonul se vinde sau
                    în 30 de zile)
                    <br />
                    👉🏻 mai mulți bani
                  </p>
                </div>

                <Button
                  variant="outlined"
                  color="success"
                  fullWidth
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    handleChangeTypePaymentAndPrice(
                      consignmentPrice,
                      "CONSIGNATIE"
                    );
                    navigate("?stateMachine=paymentInfo");
                    handleChangeLinearProgressForNext();
                  }}
                >
                  Continuă
                </Button>
              </div>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={6}>
          <Paper>
            <div
              style={{
                padding: 10,
                color: "white",
                background:
                  "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
              }}
            >
              <h2> Plată în 24-48 de ore</h2>
            </div>
            <div style={{ padding: "0px 20px 20px 20px " }}>
              <h1 style={{ fontSize: 40 }}>
                {estimatedPrice} {"  "}
                <span style={{ fontSize: 20 }}>Lei</span>
              </h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  minHeight: 90,
                }}
              >
                <CheckIcon
                  style={{
                    color: "rgba(81,158,0,1)",
                    padding: "0px 10px 20px 0px",
                  }}
                />
                <p style={{ textAlign: "left" }}>
                  Plată în 24-48 de ore ( imediat după verificarea tehnică)
                  <br />
                  👉🏻 mai puțin timp de așteptare
                </p>
              </div>

              <Button
                variant="outlined"
                color="success"
                fullWidth
                style={{ marginTop: 10 }}
                onClick={() => {
                  handleChangeTypePaymentAndPrice(estimatedPrice, "NORMAL");
                  navigate("?stateMachine=paymentInfo");
                  handleChangeLinearProgressForNext();
                }}
              >
                Continuă
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
