/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Grid, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManufacturerContext } from "../../Context/manufacturerContext";
import { getData } from "../../Services/getData";
import { LocalUrlEnum, urlEnum } from "../../Utils/utils";
import { Vocabulary } from "../../Utils/vocabulary";
import Manufacturer from "./Manufacturer";
import ShowOthers from "./ShowOthers";
import SeoInfo from "../SeoInfo";
import { Helmet } from "react-helmet";

export default function Manufacturers() {
  const navigate = useNavigate();
  const manufacturerContext = useContext(ManufacturerContext);
  const [manufacturers, setManufacturers] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const matches = useMediaQuery("(max-width: 1200px)");

  /**
   *
   */
  useEffect(() => {
    if (manufacturers.length === 0) {
      getManufacturers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  async function getManufacturers() {
    getData(`${urlEnum.clientQuestionnaires}/getAll`)
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   */
  function handleChangeShowMore() {
    setShowMore(!showMore);
  }

  function handleChangeManufacturer(name: string) {
    manufacturerContext.setManufacturer(name);
    navigate(LocalUrlEnum.devices);
  }
  /**
   *
   * @param index
   * @returns
   */
  function showManufactures(index: number, showOther: boolean) {
    return (
      <>
        <Helmet>
          <title>
            {Vocabulary.manufacturersTitle.replace(
              "{manufacturer}",
              manufacturerContext?.manufacturer
            )}
          </title>
          <meta
            name="title"
            content={Vocabulary.manufacturersTitle.replace(
              "{manufacturer}",
              manufacturerContext?.manufacturer
            )}
          />
          <meta
            name="description"
            content={Vocabulary.manufacturersDescription.replace(
              "{manufacturer}",
              manufacturerContext?.manufacturer
            )}
          />
        </Helmet>
        {showOther ? (
          <>
            {manufacturers.length > 2 ? (
              <Grid
                item
                xs={10}
                md={8}
                lg={8}
                justifyContent="center"
                alignItems="center"
                style={{ paddingTop: 0 }}
              >
                <ShowOthers handleChangeShowMore={handleChangeShowMore} />
              </Grid>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {index % 2 === 0 ? (
              <>
                <Grid item xs={10} md={8} lg={4} justifyContent="center">
                  <Manufacturer
                    manufacturer={manufacturers[index]}
                    handleChangeManufacturer={handleChangeManufacturer}
                  />
                </Grid>
                <Grid item xs={10} md={8} lg={4} justifyContent="center">
                  {index + 1 < manufacturers.length ? (
                    <Manufacturer
                      manufacturer={manufacturers[index + 1]}
                      handleChangeManufacturer={handleChangeManufacturer}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                {!matches ? (
                  <Divider
                    style={{
                      color: "#b4bec0",
                      width: matches ? "90%" : "70%",
                      margin: "auto",
                      marginTop: 0,
                      marginBottom: matches ? 0 : !showMore ? 0 : 75,
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }

  return (
    <div>
      <div style={{ color: "#647273", margin: matches ? 10 : 30 }}>
        <h1 style={{ fontSize: 37 }}> {Vocabulary.sellPhone}</h1>
        <p style={{ fontSize: 17 }}>{Vocabulary.chooseManufacturer}</p>
      </div>

      {showMore ? (
        <>
          {matches ? (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              style={{
                gridGap: "20px",
              }}
            >
              {manufacturers.map((manufacturer, index) => (
                <>{showManufactures(index, false)}</>
              ))}
            </Grid>
          ) : (
            <>
              {manufacturers.map((manufacturer, index) => (
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  style={{
                    gridGap: "60px",
                  }}
                >
                  {showManufactures(index, false)}
                </Grid>
              ))}
            </>
          )}
        </>
      ) : (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{
            gridGap: "30px",
          }}
        >
          {showManufactures(0, false)}
          {showManufactures(0, true)}
        </Grid>
      )}

      <SeoInfo />
    </div>
  );
}
