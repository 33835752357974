export const devicesColors = {
  white: "linear-gradient(#fff, #EEEEEE)",
  silver: "linear-gradient(#EBEBEB, #D3D3D3)",
  starlight: "linear-gradient(#EBEBEB, #D3D3D3)",
  graphite: "linear-gradient(#D4D8DF,#53565B)",
  gray: "linear-gradient( #DCDCDC,#565656)",
  black: "linear-gradient(#676767, #000)",
  red: "linear-gradient(#FF7A7A,#C60000)",
  orange: "linear-gradient(#FFD4AA,#FF8000)",
  coral: "linear-gradient(#FFCEBC, #FF7F50)",
  peach: "linear-gradient(#FFCEBC, #FF7F50)",
  gold: "linear-gradient(#F7EF8A,#D4AF37)",
  yellow: "linear-gradient(#FFFFBB,#FFE500)",
  green: "linear-gradient(#43FF43,#008000)",
  blue: "linear-gradient(#93BDFD, #0000FF)",
  cyan: "linear-gradient(#E5FFFF,#00DDFF)",
  crystal: "linear-gradient(#F5FCFF,#a6d8df)",
  purple: "linear-gradient(#CBC3E3,#800080)",
  violet: "linear-gradient(#CF9FFF,#7F00FF)",
  pink: "linear-gradient(#FFCAD3,#FF6EB9)",
  rose: "linear-gradient(#FFCAD3,#FF6EB9)",
  copper: "linear-gradient(#F1DBD0,#C26C1B)",
  bronze: "linear-gradient(#F1DBD0,#C26C1B)",
  midnight: "linear-gradient(#1773ff, #000054)",
};
