/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState } from "react";

export const DeviceContext = createContext({
  device: "" as any,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDevice: (value: any) => {},
});

export const DeviceContextProvider = (props: any) => {
  const setDevice = (device: any) => {
    setState({ ...state, device: device });
  };
  const initState = {
    device: "",
    setDevice: setDevice,
  };
  const [state, setState] = useState(initState);
  return (
    <DeviceContext.Provider value={state}>
      {props.children}
    </DeviceContext.Provider>
  );
};
