import { Paper } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type ShowOthersProps = {
  handleChangeShowMore: () => void;
};

export default function ShowOthers(props: ShowOthersProps) {
  const { handleChangeShowMore } = props;
  //   const matchesVerySmall = useMediaQuery("(max-width: 310px)");
  //   const matchesSmall = useMediaQuery("(max-width: 390px)");
  //   const matches = useMediaQuery("(max-width: 470px)");
  //   const matchesImagesText = useMediaQuery(
  //     "(min-width: 471px)and (max-width:530px)"
  //   );
  //   const showImages = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <Paper
        style={{
          cursor: "pointer",
          borderRadius: "100%",
          background: "linear-gradient(0.25turn,#fff, #e7ecee) ",
          height: 55,
          width: 55,
          margin: "auto",
        }}
        onClick={handleChangeShowMore}
      >
        <KeyboardArrowDownIcon fontSize="large" style={{ fontSize: 55 }} />
      </Paper>
      <p>Vezi mai mult</p>
    </>
  );
}
