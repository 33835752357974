/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography, useMediaQuery } from "@mui/material";
import { devicesColors } from "../../Utils/theme";
import { Vocabulary } from "../../Utils/vocabulary";
import { AnswersType } from "./DeviceQuestionnaire";
import QuestionsTemplate from "./QuestionsTemplate";
import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { useEffect, useState } from "react";

type ChooseStorageAndColorProps = {
  device: any;
  answers: AnswersType;
  handleChangeStorage: (storage: string, index: number) => void;
  handleChangeColor: (color: string) => void;
  classes: any;
};

function ChooseStorageAndColor(props: ChooseStorageAndColorProps) {
  const { device, answers, handleChangeColor, handleChangeStorage, classes } =
    props;
  const matches = useMediaQuery("(max-width: 850px)");
  const [configurations, setConfigurations] = useState([]);
  const colors = {
    name: Object.keys(devicesColors),
    value: Object.values(devicesColors),
  } as any;

  useEffect(() => {
    if (device && device.configurations) {
      const newConfig = device.configurations.sort(
        (first: any, second: any) => {
          return parseInt(first.storage) - parseInt(second.storage);
        }
      );
      handleChangeStorage(newConfig[0].storage, 0);
      setConfigurations(newConfig);
    }
  }, [device]);

  /**
   *
   * @param storage
   * @returns
   */
  function getStorage(storage: string) {
    const st = storage.split(" ");
    return st[0];
  }
  return (
    <QuestionsTemplate device={device}>
      <div
        style={
          matches
            ? { display: "grid", justifyContent: "center", margin: 10 }
            : {}
        }
      >
        <Typography
          style={
            matches ? { fontSize: 19, margin: 0 } : { fontSize: 19, margin: 20 }
          }
        >
          {Vocabulary.chooseStorage}
        </Typography>
        <div
          className={
            device && device.configurations.length <= 4
              ? ""
              : classes.scrollable
          }
        >
          <div
            style={
              matches
                ? {
                    display: "flex",
                    justifyContent:
                      device && device.configurations.length <= 4
                        ? "center"
                        : "flex-start",
                    overflowY: "auto",
                  }
                : {
                    display: "flex",
                    overflowY: "auto",
                  }
            }
          >
            {device &&
              configurations.map((config: any, index: number) => (
                <Button
                  key={`${index}_storage`}
                  variant="outlined"
                  style={{
                    color:
                      answers.storage === config.storage ? "white" : "#358800",
                    borderColor: "#358800",
                    backgroundColor:
                      answers.storage === config.storage ? "#358800" : "white",
                    margin: "20px 10px 20px 20px",
                  }}
                  onClick={() => handleChangeStorage(config.storage, index)}
                >
                  {getStorage(config.storage)}
                </Button>
              ))}
          </div>
        </div>
        <Typography
          style={
            matches ? { fontSize: 19, margin: 0 } : { fontSize: 19, margin: 20 }
          }
        >
          {Vocabulary.chooseColor}
        </Typography>
        <div
          style={
            matches
              ? { display: "flex", justifyContent: "center", flexWrap: "wrap" }
              : { display: "flex", flexWrap: "wrap" }
          }
        >
          {device &&
            device.configurations[answers.storageId].colors.map(
              (color: any, key: number) => {
                const index = colors.name.findIndex((name: string) =>
                  color.color.toLowerCase().includes(name)
                );
                if (color.price !== "")
                  return (
                    <div
                      key={`${key}_storage`}
                      style={{
                        padding: 5,
                        textAlign: "center",
                        backgroundColor:
                          answers.color === color.color
                            ? "#358800"
                            : "transparent",
                        borderRadius: "6px",
                        marginLeft: 10,
                        maxWidth: 70,
                        wordBreak: "normal",
                      }}
                      onClick={() => handleChangeColor(color)}
                    >
                      <div
                        style={{
                          color: index >= 0 ? "white" : "black",
                          border: "1px solid #e4eaeb",
                          background:
                            index >= 0 ? colors.value[index] : "white",
                          margin: "5px",
                          borderRadius: "6px",
                          height: 35,
                          width: 60,
                        }}
                      />
                      <p
                        style={{
                          textTransform: "capitalize",
                          fontSize: 12,
                          color:
                            answers.color === color.color ? "white" : "black",
                        }}
                      >
                        {color.color.toLowerCase()}
                      </p>
                    </div>
                  );
                return null;
              }
            )}
        </div>
      </div>
    </QuestionsTemplate>
  );
}

const styles = (theme: any) =>
  createStyles({
    scrollable: {
      position: "relative",
      margin: "0px 10px 0px 10px",
      "&:before": {
        top: 50,
        left: 0,
        position: "absolute",
        content: '"<"',
        fontWeight: "bold",
        color: "#ddd",
      },
      "&:after": {
        top: 50,
        right: 0,
        position: "absolute",
        content: '">"',
        fontWeight: "bold",
        color: "#ddd",
      },
    },
  });
export default withStyles(styles, { withTheme: true })(ChooseStorageAndColor);
