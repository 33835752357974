import { Grid, createStyles } from "@mui/material";
import { withStyles } from "@mui/styles";

function SeoInfo (props) {
    const { classes } = props;

    return <div >
        <div style={{ color: "rgb(100, 114, 115)", maxWidth: 1200, margin: "0 auto", marginTop:80 }}>
            <div >
                <h3 >Câștigi 408,3 RON când îți vinzi vechiul telefon prin YZZY</h3>
                <div className={classes.divider}></div>
                <div >
                    <p>De peste 10 ani am învățat de la toți clienții noștri, ne-am specializat și am devenit printre cei mai buni în domeniul reparării și vânzării de telefoane second hand.</p>
                </div>
                <div >
                    <p>Când îți vinzi telefonul vechi prin YZZY beneficiezi de:</p>
                </div>
            </div>
            <Grid container style={{marginTop:60}}>
                <Grid item md={4} >
                    <div className={classes.itemContainer}>
                        <span className={classes.icon}><i aria-hidden="true" className="fas fa-hands-helping"></i></span>
                        <div >
                            <h3 style={{ marginBottom: "0px" }}>Preț avantajos pentru tine</h3>
                            <p style={{ marginTop: "5px" }}>transportul este plătit de noi.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} >
                    <div className={classes.itemContainer}>
                        <span className={classes.icon}><i aria-hidden="true" className="fas fa-hourglass"></i></span>
                        <div>
                            <h3 style={{ marginBottom: "0px" }} >Procedură clară și simplă</h3>
                            <p style={{ marginTop: "5px" }}>economisești timp.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} >
                    <div className={classes.itemContainer}>
                        <span className={classes.icon}><i aria-hidden="true" className="fas fa-award"></i></span>
                        <div>
                            <h3 style={{ marginBottom: "0px" }}>Siguranță - totul este online</h3>
                            <p style={{ marginTop: "5px" }}>fără întâlniri și negocieri infinite.</p>
                        </div>
                    </div>
                </Grid>
            </Grid >
        </div>
        <section className={classes.backgoundSection}>
            <div className={classes.button}>
                <a href="https://vinde.yzzy.ro" role="button">
                    <span >
                        <span  style={{color:"white", fontSize: 25}}><i aria-hidden="true" className="fas fa-handshake"></i></span>
                        <br/>
                        <span >VINDE UȘOR ȘI RAPID!</span>
                    </span>
                </a>
            </div>
        </section>

        <div style={{ color: "rgb(100, 114, 115)", maxWidth: 1200, margin: "0 auto", marginTop:50 }}>
            <section style={{textAlign:"justify", lineHeight:1.5}}>
                <img className={classes.image} decoding="async" src="https://www.yzzy.ro/wp-content/uploads/2022/05/yzzy_profil_cornel.jpg" alt="" width="400" height="430" align="left" />
                Sunt Ionuț, fondatorul YZZY.
                De peste 10 ani conduc un service GSM. În tot acest timp pe la noi au trecut peste 10.000 de telefoane. Le reparăm, le protejăm și astfel redăm zâmbetul posesorilor.

                De multe ori, clienții ne roagă să îi ajutăm să își vândă telefonul mai vechi la un preț bun, în siguranță și cu garanție.

                Astfel a apărut <strong>YZZY</strong>.
                <br /><br />
                <h4>Este foarte ușor să îți vinzi telefonul prin YZZY!</h4>
                Tu ai putea fi următorul client care cumpără un smartphone de la Yzzy. Îți vei dori calitate, funcționalitate și preț bun. Pentru asta ne asigurăm mai întâi de toate că ceea ce tu vinzi și noi cumpărăm să se încadreze în anumite standarde. Verificăm statusul telefonului, utilizând codul IMEI. Nu vor fi preluate telefoane furate sau blocate!

                Știm, ai rămas cu gândul la acel telefon mobil din vitrina unui magazin. Ce te faci însă cu cel pe care îl ai deja? Noi avem soluția! Recuperează din investiția pe care ai facut-o și vinde-l. Vinde în siguranță. Îți garantăm că vei primi un preț corect, după o evaluare amănunțită a telefonului.
                <br /><br />
                <h4>Este atât de simplu să îți vinzi telefonul aici!</h4>
                Avem destul de multe griji, lucruri de făcut zi de zi încât să mai adăugăm pe listă și stresul despre cum să vindem un telefon mobil. Alege calea simplă! Yzzy a pornit de la conceptul practic de a face multe din telefoanele mobile la mâna a doua încă funcționale și dorite pentru publicul larg. Dacă și tu te numeri printre cei care își schimbă telefonul des, care își doresc să fie în pas cu moda și nu utilizează un telefon mobil mai mult de câteva luni, nu-i nimic. Te înțelegem perfect și te ajutăm să îți vinzi telefonul, fără prea multe bătăi de cap.

                Îți recomandăm să verifici și secțiunea de întrebări pentru a descoperi ce ai de făcut pas cu pas pentru a vinde un telefon mobil prin Yzzy.
            </section>
        </div>
        <br/><br/><br/><br/>
        <section className={classes.backgoundSection} style={{backgroundColor:"#eee", color:"#888"}}>
            <p>© {new Date().getFullYear()} Toate drepturile rezervate YZZY Mobile.</p>
        </section>
    </div >;
}


const styles = (theme) =>
    createStyles({
        divider: {
            textAlign: "center",
            paddingTop: 15,
            paddingBottom: 15,
            borderTop: "1px solid #dedede"
        },
        icon: { color: "#54a400", fontSize: 45, marginTop: 20, marginRight: 15 },
        itemContainer: { display: "flex", textAlign: "left" },
        backgoundSection: {
            marginTop: 50,
            backgroundColor: "#54a400",
            height: 150,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        button: {
            border: "1px solid #fff",
            borderRadius: 20,
            "& a": {
                textDecoration: "none !important",
                color: "#fff",
                display:"block",
                width:"100%",
                height:"100%",
                padding: "12px 30px 20px 5px",
            }
        },
        image:{
            margin:"0 25px 25px 25px"
        }
    });

export default withStyles(styles, { withTheme: true })(SeoInfo);