import { createContext, useState } from "react";

export const StepContext = createContext({
  step: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStep: (value: number) => {},
});

export const StepContextProvider = (props: any) => {
  const setStep = (step: number) => {
    setState({ ...state, step: step });
  };
  const initState = {
    step: 0,
    setStep: setStep,
  };
  const [state, setState] = useState(initState);
  return (
    <StepContext.Provider value={state}>{props.children}</StepContext.Provider>
  );
};
