/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DeviceContext } from "../../Context/deviceContext";
import { Vocabulary } from "../../Utils/vocabulary";
import { AnswerType } from "./DeviceQuestionnaire";
import ImagesForQuestions from "./ImagesForQuestions";
import QuestionsTemplate from "./QuestionsTemplate";

type QuestionsProps = {
  question: any;
  answers: Array<AnswerType>;
  numberOfQuestion: number;
  handleAddAnswer: (answer: AnswerType) => void;
};

export default function Questions(props: QuestionsProps) {
  const deviceContext = useContext(DeviceContext);
  const { question, numberOfQuestion, answers, handleAddAnswer } = props;
  const matches = useMediaQuery("(max-width: 850px)");
  const [showImages, setShowImages] = useState(false);
  const [answer, setAnswer] = useState<AnswerType>({
    questionText: question ? question.question : "",
    answerValue: "",
    price: "",
    hardStop: false,
    questions: [],
  });

  useEffect(() => {
    if (answers[numberOfQuestion]) {
      setAnswer(answers[numberOfQuestion]);
    } else {
      setAnswer({
        ...answer,
        questionText: question ? question.question : "",
        answerValue: "",
        price: "",
        questions: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answers]);

  /**
   *
   * @param event
   */
  function handleChangeAnswer(event: any, currentAnswer: any) {
    const newAnswer = Object.assign({}, answer);
    if (event.target.value === "on") {
      if (event.target.checked) {
        newAnswer.answerValue =
          `${newAnswer.answerValue};${currentAnswer.option}`.trim();
        newAnswer.price =
          `${newAnswer.price};${currentAnswer.coefficientValue} ${currentAnswer.coefficientType}`.trim();
      } else if (event.target.checked === false) {
        const answerValue = newAnswer.answerValue.split(";");
        const price = newAnswer.price.split(";");
        const index = answerValue.findIndex(
          (av: string) => av.trim() === currentAnswer.option.trim()
        );
        if (index >= 0) {
          answerValue.splice(index, 1);
          price.splice(index, 1);
        }
        newAnswer.answerValue = answerValue.join(";");
        newAnswer.price = price.join(";");
      }
    } else {
      newAnswer.answerValue = currentAnswer.option;
      newAnswer.price = `${currentAnswer.coefficientValue} ${currentAnswer.coefficientType}`;
    }
    newAnswer.hardStop = currentAnswer.hardStop;
    newAnswer.questions = currentAnswer.questions
      ? currentAnswer.questions
      : [];
    setAnswer(newAnswer);
    handleAddAnswer(newAnswer);
  }

  /**
   *
   * @param ans
   * @returns
   */
  function check(ans: string) {
    const answers = answer.answerValue.split(";");
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].trim() === ans) return true;
    }
    return false;
  }
  /**
   *
   * @returns
   */
  function showAnswers() {
    if (question && question.answerType === "multipleChoice") {
      return (
        <FormGroup
          style={matches ? { display: "grid", justifyContent: "center" } : {}}
        >
          {question.answers.map((ans: any, key: number) => {
            return (
              <FormControlLabel
                key={`${key}_question`}
                control={
                  <Checkbox
                    color="primary"
                    checked={check(ans.option.trim())}
                    onClick={(event) => handleChangeAnswer(event, ans)}
                    style={{ padding: matches ? 12 : 20, letterSpacing: 0 }}
                  />
                }
                style={{ textAlign: "left" }}
                label={ans.option}
              />
            );
          })}
        </FormGroup>
      );
    } else if (question && question.answerType === "singleChoice") {
      return (
        <RadioGroup
          value={answer.answerValue}
          row
          name="answer"
          style={
            matches
              ? { display: "grid", justifyContent: "center" }
              : { display: "grid" }
          }
        >
          {question.answers.map((answer: any, key: number) => {
            return (
              <div
                style={{ margin: matches ? "0px 10px" : 10, textAlign: "left" }}
                key={`${key}_question`}
              >
                <FormControlLabel
                  value={`${answer.option}`}
                  control={<Radio />}
                  label={answer.option}
                  onChange={(event) => handleChangeAnswer(event, answer)}
                />
              </div>
            );
          })}
        </RadioGroup>
      );
    }
  }
  return (
    <QuestionsTemplate device={deviceContext.device}>
      <h2
        style={
          matches
            ? { color: "#647273", textAlign: "center", fontSize: 20 }
            : { color: "#647273" }
        }
      >
        {question ? question.question : ""}
      </h2>
      <div style={{ width: "90%", margin: "auto" }}>{showAnswers()}</div>
      {question && question.images && question.images.length !== 0 ? (
        <p
          style={{
            cursor: "pointer",
            margin: "10px",
            color: "#647273",
          }}
          onClick={() => setShowImages(true)}
        >
          {Vocabulary.showImages}
        </p>
      ) : (
        ""
      )}
      <ImagesForQuestions
        showImages={showImages}
        images={question && question.images ? question.images : []}
        changeShowImagesValue={() => setShowImages(false)}
      />
    </QuestionsTemplate>
  );
}
