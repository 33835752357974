/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShowImages from "./ShowImages";

type ImagesForQuestionsProps = {
  classes?: any;
  images: any;
  showImages: boolean;
  changeShowImagesValue: () => void;
};

function ImagesForQuestions(props: ImagesForQuestionsProps) {
  const { classes, showImages, images, changeShowImagesValue } = props;
  return (
    <Dialog
      onClose={changeShowImagesValue}
      open={showImages}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 1000,
          },
        },
      }}
      id="previewDialogContent"
    >
      <DialogTitle className={classes.dialogTitle}>
        <p className={classes.close}>
          <CloseIcon
            onClick={changeShowImagesValue}
            className={classes.closeIcon}
          />
        </p>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <ShowImages images={images} altText="Imagine" gridSize={12} />
      </DialogContent>
    </Dialog>
  );
}

const styles = (theme: any) =>
  createStyles({
    dialogTitle: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      margin: 10,
    },
    title: {
      margin: 10,
      fontSize: "26px",
    },
    close: {
      position: "absolute",
      right: 20,
      top: 0,
    },
    closeIcon: {
      cursor: "pointer",
      fontSize: 28,
    },
  });
export default withStyles(styles, { withTheme: true })(ImagesForQuestions);
