import { createContext, useState } from "react";

export const ManufacturerContext = createContext({
  manufacturer: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setManufacturer: (value: string) => {},
});

export const ManufacturerContextProvider = (props: any) => {
  const setManufacturer = (manufacturer: string) => {
    setState({ ...state, manufacturer: manufacturer });
  };
  const initState = {
    manufacturer: "",
    setManufacturer: setManufacturer,
  };
  const [state, setState] = useState(initState);
  return (
    <ManufacturerContext.Provider value={state}>
      {props.children}
    </ManufacturerContext.Provider>
  );
};
