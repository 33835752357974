import { LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px 10px 0px 10px",
      }}
    >
      <Box sx={{ width: "70%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            backgroundColor: "#35880047",
            "& .css-5xe99f-MuiLinearProgress-bar1 ": {
              backgroundColor: "#358800",
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35, margin: "0px 10px" }}>
        <Typography variant="body2" color="#358800">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
