import { Button } from "@mui/material";
import { Vocabulary } from "../../Utils/vocabulary";

export default function HardStop() {
  return (
    <div
      style={{
        color: "#647273",
        margin: "auto",
        width: "60%",
        marginTop: 40,
      }}
    >
      <h1 style={{ marginTop: 120 }}>{Vocabulary.hardStopMessage}</h1>
      <h1>{Vocabulary.thank} </h1>
      <Button
              variant="contained"
              style={{
                background:
                  "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)",
                minWidth: 120,
                margin: 20,
                // width: matches ? "70%" : "auto",
              }}
              onClick={() => window.location.href="/"}
            >
              Înapoi la pagina principală
            </Button>
    </div>
  );
}
