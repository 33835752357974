/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Device from "./Device";
import { useContext, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/utils";
import { Vocabulary } from "../../Utils/vocabulary";
import { ManufacturerContext } from "../../Context/manufacturerContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ShowDevices() {
  const navigate = useNavigate();
  const manufacturerContext = useContext(ManufacturerContext);
  const [devices, setDevices] = useState([]);
  const [searchDevice, setSearchDevice] = useState("");
  const matchesSmall = useMediaQuery("(max-width: 600px)");
  const matches = useMediaQuery("(max-width: 1200px)");
  const matchForWidth = useMediaQuery(
    "(min-width: 1201px) and (max-width:1600px)"
  );

  useEffect(() => {
    if (manufacturerContext.manufacturer !== "") {
      const url = `${urlEnum.clientQuestionnaires}/${manufacturerContext.manufacturer}`;
      getData(url)
        .then((res) => {
          setDevices(res.data);
        })
        .catch((err) => {
          //
        });
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param e
   */
  async function onChangeDeviceName(e: any) {
    setSearchDevice(e.target.value);
    await getData(
      `${urlEnum.clientQuestionnaires}/${manufacturerContext.manufacturer}/${e.target.value}`
    )
      .then((res) => {
        setDevices(res.data);
      })
      .catch((err) => {
        //
      });
  }

  return (
    <div
      style={{
        width: matches ? "90%" : matchForWidth ? "85%" : "77%",
        margin: "auto",
      }}
    >
      <Helmet>
        <title>
          {Vocabulary.devicesTitleH2.replace(
            "{manufacturer}",
            manufacturerContext?.manufacturer
          )}
        </title>
        <meta
          name="title"
          content={Vocabulary.devicesTitleH2.replace(
            "{manufacturer}",
            manufacturerContext?.manufacturer
          )}
        />
        <meta
          name="description"
          content={Vocabulary.devicesDescription.replace(
            "{manufacturer}",
            manufacturerContext?.manufacturer
          )}
        />
      </Helmet>
      <div style={{ color: "#647273", margin: matches ? 10 : 30 }}>
        <h1 style={{ fontSize: 37 }}>
          {Vocabulary.devicesTitleH1.replace(
            "{manufacturer}",
            manufacturerContext?.manufacturer
          )}
        </h1>
        <h2>
          {Vocabulary.devicesTitleH2.replace(
            "{manufacturer}",
            manufacturerContext?.manufacturer
          )}
        </h2>
      </div>
      <TextField
        type="text"
        name="deviceName"
        label={`${Vocabulary.chooseModel}`}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{ color: "#358800" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          "& .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root ": {
            color: "#358800",
          },
          "& .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused ": {
            color: "#358800",
          },
          ".css-v4u5dn-MuiInputBase-root-MuiInput-root:after": {
            borderBottom: "1px solid #358800",
          },
          ".css-v4u5dn-MuiInputBase-root-MuiInput-root:before": {
            borderBottom: "1px solid #358800",
          },
        }}
        style={{
          width: matchesSmall
            ? "80%"
            : matches
            ? "50%"
            : matchForWidth
            ? "40%"
            : "30%",
        }}
        value={searchDevice}
        onChange={(e) => onChangeDeviceName(e)}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 20,
          paddingBottom: 5,
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {devices.map((device, key) => (
          <Grid
            item
            xs={4}
            sm={4}
            md={3}
            lg={2}
            style={{ margin: matches ? "auto" : 10 }}
            key={key}
          >
            <Device device={device} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
