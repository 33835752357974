/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { StepContext } from "../../Context/stepContext";
import { Vocabulary } from "../../Utils/vocabulary";
import { LocalUrlEnum } from "../../Utils/utils";

type ManufacturerProps = {
  manufacturer: string;
  handleChangeManufacturer: (name: string) => void;
};

export default function Manufacturer(props: ManufacturerProps) {
  const { manufacturer, handleChangeManufacturer } = props;
  const stepContext = useContext(StepContext);
  const matchesVerySmall = useMediaQuery("(max-width: 310px)");
  const matchesSmall = useMediaQuery("(max-width: 390px)");
  const matches = useMediaQuery("(max-width: 470px)");
  const matchesImagesText = useMediaQuery(
    "(min-width: 471px)and (max-width:530px)"
  );
  const showImages = useMediaQuery("(max-width: 600px)");
  const showText = useMediaQuery("(min-width: 1200px) and (max-width:1500px)");
  return (
    <Paper
      style={{
        cursor: "pointer",
        borderRadius: "20px",
        border: " 0.01px solid #e4eaeb",
        boxShadow: "1px 1px 12px 5px #e9e9e9",
        background: "linear-gradient(0.25turn,#fff, #e7ecee) ",
        height: showImages ? 150 : 200,
        overflow: "hidden",
      }}
      onClick={(e: any) => {
        e.preventDefault();
        stepContext.setStep(1);
        handleChangeManufacturer(manufacturer);
      }}
    >
      <a
        id="manufacturer"
        href={`${LocalUrlEnum.devices}`}
        style={{ textDecoration: "none" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={5} md={5}>
            <div
              style={{
                textAlign: "left",
                marginTop: matchesSmall ? 10 : showImages ? 0 : 40,
                marginLeft: matchesSmall ? -30 : 15,
              }}
            >
              <img
                src={
                  process.env.REACT_APP_URL_STATUS === "STAGING"
                    ? `${process.env.REACT_APP_URL}manufactures/${
                        manufacturer ? manufacturer.toLowerCase() : ""
                      }.png`
                    : `/manufactures/${
                        manufacturer ? manufacturer.toLowerCase() : ""
                      }.png`
                }
                alt={manufacturer}
                style={{
                  height: matchesSmall
                    ? 200
                    : matchesImagesText
                    ? 220
                    : matches
                    ? 190
                    : 250,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={5} md={5} lg={5}>
            <div
              style={{
                display: "flex",
                alignItems: matches ? "center" : "right",
                justifyContent: "right",
                height: matches ? 150 : 140,
                color: "#647273",
              }}
            >
              <h1
                style={{
                  fontWeight: "normal",
                  verticalAlign: "middle",
                  marginTop: matches ? 10 : 40,
                  marginRight: matches ? 0 : 15,
                  fontSize: matchesVerySmall
                    ? "1.1em"
                    : matchesImagesText
                    ? "1.8em"
                    : matches
                    ? "1.6em"
                    : "2em",
                }}
              >
                {manufacturer}
              </h1>
            </div>
            {matches ? (
              ""
            ) : (
              <p
                style={{
                  textAlign: "left",
                  fontSize: "12px",
                  marginLeft: showText ? 30 : 0,
                  color: "#647273",
                }}
              >
                &nbsp; &nbsp; {Vocabulary.showDevicesList} "{manufacturer}".
              </p>
            )}
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <div
              style={{
                width: "100%",
                background:
                  "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: showImages ? 150 : 200,
              }}
            >
              <ArrowForwardIosIcon
                style={{
                  verticalAlign: "middle",
                  color: "white",
                  fontSize: showImages ? 30 : 70,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </a>
    </Paper>
  );
}
