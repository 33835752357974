import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DeviceContext } from "../../Context/deviceContext";
import { StepContext } from "../../Context/stepContext";
import { LocalUrlEnum } from "../../Utils/utils";

/* eslint-disable @typescript-eslint/no-explicit-any */
type DeviceProps = {
  device: any;
};

export default function Device(props: DeviceProps) {
  const { device } = props;
  const stepContext = useContext(StepContext);
  const deviceContext = useContext(DeviceContext);
  const navigate = useNavigate();
  const matchesSmall = useMediaQuery("(max-width: 600px)");
  const deviceName = device.deviceName.toLowerCase().replaceAll(" ", "-");

  function handleChangeDevice(device: any) {
    deviceContext.setDevice(device);
    navigate(`${LocalUrlEnum.vand}/${deviceName}`);
  }

  return (
    <Card
      style={{
        borderRadius: "20px",
        border: !matchesSmall ? "0.01px solid #e4eaeb" : "none",
        boxShadow: !matchesSmall ? "1px 1px 12px 5px #e9e9e9" : "none",
      }}
    >
      <a
        id="device"
        href={`${LocalUrlEnum.vand}/${deviceName}`}
        style={{ textDecoration: "none", color: "black" }}
      >
        <CardActionArea
          onClick={(e: any) => {
            e.preventDefault();
            stepContext.setStep(2);
            handleChangeDevice(device);
          }}
          style={{
            // height: "auto",
            height: matchesSmall ? 106 : 230,
            overflow: "hidden",
          }}
        >
          <CardMedia
            component="img"
            style={{
              width: matchesSmall ? "80px" : "150px",
              margin: "auto",
              marginTop: 5,
            }}
            image={
              device.images && device.images.length > 0
                ? process.env.REACT_APP_URL_STATUS === "STAGING"
                  ? `${process.env.REACT_APP_URL}${device.images[0]}`
                  : device.images[0]
                : process.env.REACT_APP_URL_STATUS === "STAGING"
                ? `${process.env.REACT_APP_URL}manufactures/NoImage.png`
                : "/manufactures/NoImage.png"
            }
            alt={device.deviceName}
          />
        </CardActionArea>
        <CardActionArea
          onClick={(e: any) => {
            e.preventDefault();
            stepContext.setStep(2);
            handleChangeDevice(device);
          }}
          style={{ padding: 5 }}
        >
          <Typography
            gutterBottom
            style={{
              fontSize: 16,
              margin: 0,
              minHeight: 72,
              textTransform: "capitalize",
              color: "#647273",
            }}
            component="div"
          >
            {device.deviceName.toLowerCase()}
          </Typography>
        </CardActionArea>
      </a>
      <CardContent
        style={{
          textAlign: "center",
          background:
            "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)",
          color: "white",
          minHeight: matchesSmall ? "0px" : "40px",
          height: matchesSmall ? 1 : "auto",
          padding: matchesSmall ? 0 : "inherit",
        }}
      >
        <Typography
          component="div"
          style={{
            fontSize: 14,
            fontWeight: matchesSmall ? 400 : 700,
            color: "white",
            padding: 6,
            minHeight: 45,
          }}
        >
          {device.maxPrice === device.minPrice
            ? `Pană la ${device.maxPrice}lei `
            : `Prețuri maxime ${device.minPrice} lei - ${device.maxPrice} lei `}
        </Typography>
      </CardContent>
    </Card>
  );
}
