/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

const token = localStorage.getItem("access_token");
let axiosConfig = {} as any;
if (token) {
  axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
} else {
  axiosConfig = {};
}
if (process.env.REACT_APP_URL_STATUS === "STAGING")
  axiosConfig.baseURL = process.env.REACT_APP_URL;

const request = axios.create(axiosConfig);

// request.interceptors.response.use(function (config) {

//   // Do something before request is sent
//   return config;
// });

export default request;
