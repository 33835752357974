/* eslint-disable @typescript-eslint/no-explicit-any */
import QuestionsTemplate from "./QuestionsTemplate";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";

type PersonalDataProps = {
  title: string;
  device: any;
  personalData: any;
  personalInformation: any;
  handleChangePersonalData: (personalData: any) => void;
  changeInvalidEmailValue: (value: boolean) => void;
  children?: any;
  finishOrder: () => void;
};

export default function PersonalData(props: PersonalDataProps) {
  const {
    title,
    device,
    personalData,
    personalInformation,
    handleChangePersonalData,
    changeInvalidEmailValue,
    finishOrder,
    children,
  } = props;
  const matches = useMediaQuery("(max-width: 850px)");

  useEffect(() => {
    window.scrollTo(0,0);
    if (Object.keys(personalData).length === 0) {
      finishOrder();
    }
  }, []);
  /**
   *
   * @param type
   * @param required
   * @param isMessage
   * @returns
   */
  function validateField(type: string, required: boolean, isMessage: boolean) {
    if (type === "email") {
      if (required) {
        if (isMessage) return ["Email invalid", "Acest câmp este obligatoriu"];
        return ["isEmail", "required"];
      }
      if (isMessage) return ["Email invalid"];
      return ["isEmail"];
    }
    if (required) {
      if (isMessage) return ["Acest câmp este obligatoriu"];
      return ["required"];
    }
    return [""];
  }

  /**
   *
   * @param event
   */
  function handleChange(event: any) {
    const newPersonalInformation = Object.assign({}, personalInformation);
    newPersonalInformation[event.target.name] = event.target.value;
    handleChangePersonalData(newPersonalInformation);
  }

  function validateEmail(e: any) {
    if (e.target.type === "email") {
      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value)) {
        changeInvalidEmailValue(false);
      } else {
        changeInvalidEmailValue(true);
      }
    }
  }
  return (
    <QuestionsTemplate device={device}>
      <ValidatorForm
        onSubmit={() => ""}
        onBlur={(e: any) => validateEmail(e)}
        style={{ width: matches ? "95%" : "80%" }}
      >
        <h2
          style={
            matches
              ? { textAlign: "center", color: "#647273" }
              : { color: "#647273", margin: 10 }
          }
        >
          {title}
        </h2>
        {Object.keys(personalData).length !== 0 &&
          personalData.fields.map((field: any, key: number) => (
            <TextValidator
              key={`${key}_personalData`}
              type={field.type}
              name={field.name}
              label={field.name}
              placeholder={field.hint}
              value={
                personalInformation[field.name]
                  ? personalInformation[field.name]
                  : ""
              }
              fullWidth
              validators={validateField(field.type, field.required, false)}
              errorMessages={validateField(field.type, field.required, true)}
              style={
                matches
                  ? { margin: 10, padding: "5px !important" }
                  : { margin: "20px 10px", padding: "5px !important" }
              }
              onChange={(event) => {
                handleChange(event);
              }}
            />
          ))}
        {children}
      </ValidatorForm>
    </QuestionsTemplate>
  );
}
