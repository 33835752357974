import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../Components/Dashboard/Dashboard";
import DeviceQuestionnaire from "../Components/DeviceQuestionnaire/DeviceQuestionnaire";
import HardStop from "../Components/DeviceQuestionnaire/HardStop";
import ShowDevices from "../Components/Devices/ShowDevices";
import Manufacturers from "../Components/Manufacturers/Manufacturers";
import { DeviceContextProvider } from "../Context/deviceContext";
import { ManufacturerContextProvider } from "../Context/manufacturerContext";
import { StepContextProvider } from "../Context/stepContext";
import { LocalUrlEnum } from "../Utils/utils";

const Router = () => {
  function addDashboard(component: object) {
    return <Dashboard>{component}</Dashboard>;
  }
  return (
    <StepContextProvider>
      <ManufacturerContextProvider>
        <DeviceContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={addDashboard(<Manufacturers />)} />
              <Route
                path={LocalUrlEnum.devices}
                element={addDashboard(<ShowDevices />)}
              />
              <Route
                path={`${LocalUrlEnum.vand}/:name`}
                element={addDashboard(<DeviceQuestionnaire />)}
              />
              <Route
                path={LocalUrlEnum.hardStop}
                element={addDashboard(<HardStop />)}
              />
            </Routes>
          </BrowserRouter>
        </DeviceContextProvider>
      </ManufacturerContextProvider>
    </StepContextProvider>
  );
};
export default Router;
