export const Vocabulary = {
  hardStopMessage:
    "Ne pare rău, situația tehinică actuală a dispozitivului dumneavoastră, nu ne permite achiziționarea acestuia.",
  thank: "Vă mulțumim!",
  chooseStorage: " Alegeți capacitatea de storcare",
  chooseColor: "Alegeți culoarea",
  pleaseSelectColorAndStorage: "Vă rugăm selectați stocarea și culoarea!",
  pleaseSelectTheAnswer: "Vă rugăm selectați răspunsul!",
  pleaseCompletePersonalData: "Vă rugăm completați datele personale!",
  chooseConfiguration: "Alegeți specificațiile pentru ",
  back: "Înapoi",
  next: "Următorul",
  finish: "Finalizează",
  personalData: " Date personale",
  paymentInfo: "Informații plată",
  completeOrder: "Comanda dumneavoastră a fost înregistrată cu succes.",
  congratulations: "Felicitări!",
  contactText: "Urmăriți emailul pentru următorii pași. ",
  homePage: "Pagina principală",
  question: "Întrebarea ",
  sellPhone: "Ce telefon vrei să vinzi?",
  chooseDeviceModel: "Vă rugăm să alegeți modelul de telefon.",
  chooseModel: "Alegeți un model",
  chooseManufacturer:
    "Pentru a alege modelul de telefon, te rugăm să alegi mai întâi producătorul acestuia.",
  showDevicesList:
    "Alege pentru a vizualiza lista cu dispozitive din categoria",
  manufacturers: "Producători",
  devices: "Dispozitive",
  device: "Dispozitiv",
  estimatedPrice: "Prețul estimat este",
  estimatedPriceReminder:
    "După ce termini de completat formularul vei primi oferta estimată de preț.",
  invalidEmail: "Email invalid",
  termsError: "Acceptați termenii și condițiile pentru a continua",
  terms:
    "Accept termenii și condițiile și declar pe propria raspundere ca dispozitivul este din patrimoniul propriu",
  questionnaire: "Chestionar",
  showImages: "Vezi imagini demonstrative",
  offer: "Vezi oferta",
  place: "Plasează",
  showMore: "Vizualizati alți producători",
  vand: "Vand",
  devicesTitleH1: "Cauți să vinzi un telefon {manufacturer}?",
  devicesTitleH2:
    "Vinde rapid telefonul tau {manufacturer}. Buy Back YZZY la telefonul vechi.",
  devicesDescription:
    "Cauti sa iti vinzi telefonul {manufacturer} ? Este foarte YZZY procesul de achizitonare al vechiului tau telefon. Obtine pretul in cateva minute",

  deviceTitle: "Vinde-ti telefonul {manufacturer} rapid | YZZY",
  deviceDescription:
    "Vinzi ușor și rapid telefonul tau {manufacturer}. Iti oferim un pret avantajos, transport gratuit, totul online. Tu doar trebuie sa-l impachetezi!",
  manufacturersTitle: "Vinde telefonul ușor și rapid | YZZY",
  manufacturersDescription:
    "Vinzi ușor și rapid telefonul tau vechi. Iti oferim un pret avantajos, transport gratuit, totul online. Tu doar trebuie sa-l impachetezi!",
} as any;
